import React, { FC, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import Typography from "components/Typography";
import TextField from "components/TextField";
import COLORS from "constants/Colors";
import ErrorText from "components/ErrorText";
import Button from "components/Button";
import { Detail } from "./styled";

interface CheckKYCStatusPropsType {
  kycEmail: string;
  setKycEmail: (email: string) => void;
  kycEmailError: string;
  setKycEmailError: (error: string) => void;
  isMobile: boolean;
  handleVerifyAndUse: () => void;
  checkingKyc?: boolean;
}

const CheckKYCStatus: FC<CheckKYCStatusPropsType> = ({
  kycEmail,
  setKycEmail,
  kycEmailError,
  setKycEmailError,
  isMobile,
  handleVerifyAndUse,
  checkingKyc = false,
}) => {
  const { t } = useTranslation();

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKycEmail(e.target.value);
    setKycEmailError("");
  };

  return (
    <Grid container className={isMobile ? "p-4" : "px-5 py-3"}>
      <Grid style={{ textAlign: "center" }} item className="mt-2 w-100" justify="center">
        <Typography color={COLORS.Dark} variant="title2">
          {t("deeplePay.verifyBilling.checkKYC.title")}
        </Typography>
      </Grid>
      <Grid item xs={12} className="mt-3 mb-2">
        <Typography color={COLORS.DarkLight} variant="body3">
          {t("deeplePay.verifyBilling.checkKYC.description")}
        </Typography>
      </Grid>
      <Grid item xs={12} className="mb-4">
        <TextField
          fullWidth
          variant="outlined"
          placeholder={t("deeplePay.verifyBilling.checkKYC.placeholder")}
          value={kycEmail}
          onChange={handleEmailChange}
          error={Boolean(kycEmailError)}
          disabled={checkingKyc}
        />
        {kycEmailError && <ErrorText className="pt-1">{kycEmailError}</ErrorText>}
      </Grid>
      <Grid item xs={12} className="mb-3">
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleVerifyAndUse}
          disabled={checkingKyc}
        >
          {checkingKyc 
            ? t("deeplePay.verifyBilling.button.checking")
            : t("deeplePay.verifyBilling.button.verifyAndUse")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Detail>
          <Typography variant="body3" color={COLORS.Accent}>
            {t("deeplePay.verifyBilling.checkKYC.instruction")}
          </Typography>
        </Detail>
      </Grid>
    </Grid>
  );
};

export default CheckKYCStatus; 