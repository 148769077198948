import React, { FC } from "react";
import { useParams, Switch, Redirect, Route } from "react-router-dom";
import { BILLING, PLAN, PLAN_USAGE, PLAN_UPGRADE, PLAN_ADDON, PROJECT, TRANSACTION } from "constants/Router";
import Billing from "domain/Billing";
import NewDesignUpgradePlan from "domain/Billing/Plan/PlanUpgrade/UpgradePlan";
import PlanUsageContainer from "domain/Billing/Plan/PlanUsage";
import AddonContainer from "domain/Addon";
import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";
import useTabWithLink from "utils/hooks/useTabWithLink";
import useGetProject from "utils/hooks/useGetProject";
import PrivateRoute from "routers/PrivateRoute";
import { ProjectIdType } from "types/Project";
import { NotFound } from "pages/NotFound";
import { TAB_CONFIGS_DEFAULT } from "pages/PlanUsage/tabsConfig";
import LockedAccount from "pages/LockedAccount";
import Transaction from "domain/DeeplePay/Transaction";

const PlanUsageSubRoute: FC = () => {
  const { projectId } = useParams<ProjectIdType>();
  const { isUsingNotSupportedPackage } = useGetProject(projectId);
  const { loading, isOwner, isAdmin } = useGetUserWithPermission(projectId);
  const { rootPath, defaultTabPath } = useTabWithLink(TAB_CONFIGS_DEFAULT, PLAN, projectId);

  return (
    <Switch>
      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${PLAN_USAGE}`}
        isAllowed={isOwner}
        redirectTo="/"
        component={PlanUsageContainer}
      />

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${PLAN_UPGRADE}`}
        isAllowed={isOwner}
        redirectTo="/"
        component={NewDesignUpgradePlan}
      />

      <PrivateRoute exact loading={loading} path={`${rootPath}/${PLAN_ADDON}`} isAllowed={isOwner} redirectTo="/">
        {isUsingNotSupportedPackage ? (
          <LockedAccount title="planAddon.error.title" description="planAddon.error.description" />
        ) : (
          <AddonContainer />
        )}
      </PrivateRoute>

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${BILLING}`}
        isAllowed={isOwner}
        redirectTo="/"
        component={Billing}
      />

      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${TRANSACTION}`}
        isAllowed={isAdmin}
        redirectTo="/"
        component={Transaction}
      />

      <PrivateRoute exact loading={loading} path={`${rootPath}`} isAllowed={isOwner} redirectTo="/">
        <Redirect to={defaultTabPath ? `/${PROJECT}/${projectId}/${PLAN}/${defaultTabPath}` : `/${PROJECT}`} />
      </PrivateRoute>
      <Route path={`${rootPath}/*`}>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default PlanUsageSubRoute;
