import React, { memo, useState } from "react";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";

import { NO_DEEPLE_PAY_TRANSACTIONS } from "constants/Image";

import Table from "components/Table";
import { UnknownData } from "types/TransactionReport";
import { ShowingResult, RowsSelectorPerPage } from "components/TablePagination";
import Grid from "components/Grid";
import useIsDesktop from "utils/hooks/useIsDesktop";
import useShipmentTransaction from "./hooks/useShipmentTransaction";

import { GridPagination, GridRowsPerPage, GridShowingResult, Typography, PaginationWrapper } from "../../styled";
import MobileShipmentTable from "./MobileShipmentTable";
import { tableHooks, getColumns } from "./config";
import { ROWS_PER_PAGE, ROWS_PER_PAGES, DEFAULT_PAGE_START } from "../config";

export const ShipmentTable = ({ projectId, startDate, endDate }: { projectId: string, startDate: string, endDate: string }) => {
  const isDesktop = useIsDesktop();
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [page, setPage] = useState(DEFAULT_PAGE_START);
  
  const {
    results,
    isLoading,
    total,
  } = useShipmentTransaction({ 
    projectId, 
    startDate: new Date(startDate), 
    endDate: new Date(endDate),
    page,
    rowsPerPage,
  });

  const handlePageClick = (data: { selected: number }) => {
    const { selected } = data;
    setPage(selected + 1);
  };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(DEFAULT_PAGE_START);
  };

  const tableColumns = getColumns();

  return (
    <>
      {isDesktop ? (
        <Table<UnknownData, Record<string, UnknownData>>
          data={results}
          columns={tableColumns}
          hooks={tableHooks}
          isBlurContent={isLoading}
        />
      ) : (
        <MobileShipmentTable data={results} />
      )}

      {!isLoading && total > 0 && (
        <Grid container>
          <Grid item className="px-2" container xs={12} alignItems="center" justify="center">
            <GridShowingResult item xs={6} md="auto" lg="auto">
              <ShowingResult page={page} rowsPerPage={rowsPerPage} total={total} />
            </GridShowingResult>
            
            <GridPagination item xs={12} md="auto" lg="auto" container>
              <PaginationWrapper>
                <ReactPaginate
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  forcePage={page - 1}
                  pageCount={Math.ceil(total / rowsPerPage) || 0}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageClick}
                  activeClassName="active"
                  containerClassName="d-flex flex-wrap p-0"
                  pageClassName="page-no"
                  breakClassName="break"
                  previousClassName="page-no previous"
                  nextClassName="page-no next"
                />
              </PaginationWrapper>
            </GridPagination>

            <GridRowsPerPage item xs={6} md="auto" lg="auto" container alignItems="center" justify="flex-end">
              <Grid item>
                <Typography className="d-inline-block mr-2" color="darkGray">
                  {t("salesReport.rowsSelector.show")}
                </Typography>
              </Grid>
              <Grid item>
                <RowsSelectorPerPage
                  options={ROWS_PER_PAGES}
                  onChange={handleChangeRowsPerPage}
                  rowsPerPage={rowsPerPage}
                />
              </Grid>
              <Grid item>
                <Typography className="d-inline-block ml-2" color="darkGray">
                  {t("salesReport.rowsSelector.perPage")}
                </Typography>
              </Grid>
            </GridRowsPerPage>
          </Grid>
        </Grid>
      )}

      {!isLoading && total === 0 && (
        <Grid container justify="center" style={{ minHeight: "inherit" }}>
          <div className="d-flex flex-column justify-content-center text-center">
            <img src={NO_DEEPLE_PAY_TRANSACTIONS} alt="No LeanBiz pay transaction" />
            <Typography className="mt-3" variant="title2" color="lightBlue">
              {t("deeplePay.noDataFound")}
            </Typography>
          </div>
        </Grid>
      )}
    </>
  );
};

export default memo(ShipmentTable); 