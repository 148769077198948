import React from "react";
import moment from "moment";
import { CellProps, useSortBy, useFlexLayout } from "react-table";
import i18n from "utils/i18n";
import Typography from "components/Typography";
import { convertPriceFormat } from "utils/common";
import COLORS from "constants/Colors";
import { ORDER_STATUSES } from "constants/SalesReport";

export const tableHooks = [useSortBy, useFlexLayout];

export const getColumns = <T extends object>() => {
  return [
    {
      Header: i18n.t("billing.transactionReport.shipment.orderNumber"),
      accessor: "orderNumber",
      width: 150,
      Cell: (cell: CellProps<T>) => {
        return (
          <Typography variant="body3" color={COLORS.DarkGray}>
            {cell.value || "-"}
          </Typography>
        );
      },
    },
    {
      Header: i18n.t("billing.transactionReport.shipment.orderStatus"),
      accessor: "state",
      width: 150,
      Cell: (cell: CellProps<T>) => {
        return (
          <div className="status">
            <Typography variant="body3" color={COLORS.DarkGray}>
              {i18n.t(ORDER_STATUSES[cell.value]) || "-"}
            </Typography>
          </div>
        );
      },
    },
    {
      Header: i18n.t("billing.transactionReport.shipment.customerName"),
      accessor: "customerName",
      width: 200,
      Cell: (cell: CellProps<T>) => {
        return (
          <Typography variant="body3" color={COLORS.DarkGray}>
            {cell.value || "-"}
          </Typography>
        );
      },
    },
    {
      Header: i18n.t("billing.transactionReport.shipment.shippingCost"),
      accessor: "shippingPrice",
      width: 150,
      Cell: (cell: CellProps<T>) => {
        return (
          <Typography variant="body3" color={COLORS.DarkGray}>
            {cell.value ? `฿ ${convertPriceFormat(cell.value)}` : "-"}
          </Typography>
        );
      },
    },
    {
      Header: i18n.t("billing.transactionReport.shipment.date"),
      accessor: "createdAt",
      width: 200,
      Cell: (cell: CellProps<T>) => {
        return (
          <Typography variant="body3" color={COLORS.DarkGray}>
            {cell.value ? moment(cell.value).format("DD/MM/YYYY HH:mm") : "-"}
          </Typography>
        );
      },
    },
  ];
}; 