export enum Bank {
  // ANZ_BANK = "ANZ_BANK",
  // BAAC = "BAAC",
  BANGKOK_BANK = "BANGKOK_BANK",
  // BANK_OF_AMERICA = "BANK_OF_AMERICA",
  // BANK_OF_CHINA = "BANK_OF_CHINA",
  // BANK_OF_THAILAND = "BANK_OF_THAILAND",
  BAY = "BAY",
  // BNP_PARIBAS = "BNP_PARIBAS",
  // CIMB = "CIMB",
  // CITIBANK = "CITIBANK",
  // DEUTSCHE_BANK = "DEUTSCHE_BANK",
  // GHB = "GHB",
  // GSB = "GSB",
  // HONGKONG_AND_SHANGHAI = "HONGKONG_AND_SHANGHAI",
  // ICBC = "ICBC",
  // ISLAMIC_BANK = "ISLAMIC_BANK",
  // JP_MORGAN_CHASE = "JP_MORGAN_CHASE",
  KBANK = "KBANK",
  // KIATNAKIN = "KIATNAKIN",
  KTB = "KTB",
  // LH_BANK = "LH_BANK",
  // MEGA = "MEGA",
  // MIZUHO = "MIZUHO",
  // RBS = "RBS",
  SCB = "SCB",
  // STANDARD_CHARTERED = "STANDARD_CHARTERED",
  // SUMITOMO_MITSUI = "SUMITOMO_MITSUI",
  // TISCO = "TISCO",
  // THAI_CREDIT = "THAI_CREDIT",
  TTB = "TTB",
  // UOB = "UOB",
}
