import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import Typography from "components/Typography";
import COLORS from "constants/Colors";
import { Control, DeepMap, FieldError } from "react-hook-form";
import { ImageUrl, UploadDirectoryType } from "types/Image";
import DropZoneUpload from "components/DropzoneUpload";
import ImageUpload from "components/ImageUpload";
import { AddImageBox } from "components/ImageUpload/styled";
import ErrorText from "components/ErrorText";
import { UPLOAD_IMAGE } from "constants/Image";
import { BrowseButton, ExampleText, ImageWrapper, UploadBox } from "./styled";
import { SelfWithIDData } from ".";

interface SelfWithIDImagePropsType {
  projectId: string;
  isMobile: boolean;
  selfWithIdImage?: string;
  errors?: DeepMap<SelfWithIDData, FieldError>;
  register: any;
  control: Control<SelfWithIDData>;
  handleChangeImage: (type: "personal" | "bankAccount" | "selfWithId", image: ImageUrl[]) => void;
  handleRemoveImage: (type: "personal" | "bankAccount" | "selfWithId") => void;
  setExampleType: (value: "personal" | "bankAccount" | "business" | "selfWithId") => void;
}

const SelfWithIDImage: FC<SelfWithIDImagePropsType> = ({
  isMobile,
  selfWithIdImage,
  handleChangeImage,
  handleRemoveImage,
  projectId,
  errors,
  setExampleType,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container className={isMobile ? "p-4" : "px-5 py-3"}>
      <Grid style={{ textAlign: "center" }} item className="mt-2 w-100" justify="center">
        <Typography color={COLORS.Dark} variant="title2">
          {t("deeplePay.verifyBilling.selfWithId.title")}
        </Typography>
      </Grid>
      <Grid container className="p-0 m-0">
        <UploadBox item>
          {/* <Grid item className="mt-2 mb-0 title">
            <Typography color={COLORS.DarkLight} variant="body3">
              {t("deeplePay.verifyBilling.selfWithId.instruction")}
              <ExampleText className="pl-1" onClick={() => setExampleType("selfWithId")}>
                {t("deeplePay.verifyBilling.bankingInfo.seeExample")}
              </ExampleText>
            </Typography>
          </Grid> */}
          {selfWithIdImage ? (
            <ImageWrapper container justify="center" className="mt-2 mb-3">
              <ImageUpload
                height={160}
                onChange={(img: ImageUrl[]) => handleChangeImage("selfWithId", img)}
                onRemove={() => handleRemoveImage("selfWithId")}
                image={selfWithIdImage}
                directoryType={UploadDirectoryType.PROJECT_CERTIFICATION}
              />
            </ImageWrapper>
          ) : (
            <DropZoneUpload
              projectId={projectId}
              UploadDirectory={UploadDirectoryType.PROJECT_CERTIFICATION}
              onChange={(img: ImageUrl[]) => handleChangeImage("selfWithId", img)}
              limitItem={1}
              maxSizeMB={2}
              multiple={false}
            >
              <AddImageBox height={150}>
                <img src={UPLOAD_IMAGE} alt="upload-self-with-id" />
                <Typography className="mt-2" variant="title8" color="gray">
                  {t("Drag and drop")}
                </Typography>
                <Typography className="my-2" variant="body3" color="gray">
                  {t("OR")}
                </Typography>
                <BrowseButton disabled size="small">
                  <Typography variant="body3" className="py-2 px-4" color={COLORS.White}>
                    {t("Browse")}
                  </Typography>
                </BrowseButton>
              </AddImageBox>
            </DropZoneUpload>
          )}
        </UploadBox>
        <ErrorText className="pb-2">
          {errors?.selfWithIdImage && t(errors?.selfWithIdImage.message as string)}
        </ErrorText>
      </Grid>
    </Grid>
  );
};

export default SelfWithIDImage; 