import { OptionsType, OptionType } from "react-select";

// TODO:  move to type folder for now waiting for product filter
export enum PlatformType {
  LINE = "LINE",
  FACEBOOK = "FACEBOOK",
  OFFLINE = "OFFLINE",
}

export enum orderByType {
  DESC = "DESC",
  ASC = "ASC",
}

export enum OrderStateType {
  // ALL = "ALL", disable this field cause its not make sense to add "ALL" as input filter
  ABANDONED = "ABANDONED",
  CANCELLED = "CANCELLED",
  COD_PICKED_UP = "COD_PICKED_UP",
  COD_PREPARE_TO_SHIP = "COD_PREPARE_TO_SHIP",
  COD_REJECTED = "COD_REJECTED",
  COMPLETED = "COMPLETED",
  ORDER_PLACED = "ORDER_PLACED",
  PENDING_FOR_SHIPPING_COST = "PENDING_FOR_SHIPPING_COST",
  PREPARE_TO_SHIPPING = "PREPARE_TO_SHIPPING",
  REFUNDED = "REFUNDED",
  VOIDED = "VOIDED",
  WAIT_FOR_PAYMENT_APPROVE = "WAIT_FOR_PAYMENT_APPROVE",
}

export enum DeeplepayPaymentMethodType {
  CASH_ON_DELIVERY = "CASH_ON_DELIVERY",
  DEEPLE_PAY = "DEEPLE_PAY",
}

export enum PaymentMethodType {
  // BANK_QR_CODE = "BANK_QR_CODE",
  BANK_TRANSFER = "BANK_TRANSFER",
  // BILL_PAYMENT_CEN_PAY = "BILL_PAYMENT_CEN_PAY",
  CASH_ON_DELIVERY = "CASH_ON_DELIVERY",
  // CHILL_PAY_OTHER_METHOD = "CHILL_PAY_OTHER_METHOD",
  // CREDIT_CARD_K_BANK = "CREDIT_CARD_K_BANK",
  // ELECTRONIC_PAYMENT_ALI_PAY = "ELECTRONIC_PAYMENT_ALI_PAY",
  // ELECTRONIC_PAYMENT_BLUE_PAY = "ELECTRONIC_PAYMENT_BLUE_PAY",
  // ELECTRONIC_PAYMENT_LINE_PAY = "ELECTRONIC_PAYMENT_LINE_PAY",
  // ELECTRONIC_PAYMENT_WE_CHAT_PAY = "ELECTRONIC_PAYMENT_WE_CHAT_PAY",
  // INSTALLMENT_K_BANK = "INSTALLMENT_K_BANK",
  // INSTALLMENT_T_BANK = "INSTALLMENT_T_BANK",
  // INTERNET_BANKING_BAY = "INTERNET_BANKING_BAY",
  // INTERNET_BANKING_BBL = "INTERNET_BANKING_BBL",
  // INTERNET_BANKING_KTB = "INTERNET_BANKING_KTB",
  // INTERNET_BANKING_SCB = "INTERNET_BANKING_SCB",
  // INTERNET_BANKING_T_BANK = "INTERNET_BANKING_T_BANK",
  // PAY_PLUS_K_BANK = "PAY_PLUS_K_BANK",
  PROMPT_PAY = "PROMPT_PAY",
}

export enum ShippingMethodType {
  STANDARD = "STANDARD",
  EXPRESS = "EXPRESS",
  PICK_UP = "PICK_UP",
}

export enum OrderSortBy {
  ID = "id",
  ORDER_NUMBER = "orderNumber",
  STATE = "state",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  CUSTOMER_ID = "customerId",
  PLATFORM = "platform",
  CUSTOMER_NAME = "customerName",
  EMAIL = "email",
  PHONE_NUMBER = "phoneNumber",
  BILL_NUMBER = "billNumber",
  SHIPPING_METHOD = "shippingMethod",
  SHIPPING_PRICE = "shippingPrice",
  STREET_ADDRESS = "streetAddress",
  STREET_ADDRESS_2 = "streetAddress2",
  TRACKING_NUMBER = "trackingNumber",
  VAT_OPTIONS = "vatOptions",
  DISCOUNT_TOTAL_PRICE = "discountTotalPrice",
  GRAND_TOTAL = "grandTotal",
  PAYMENT_AT = "paymentAt",
  PAYMENT_METHOD_TYPE = "paymentMethodType",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  CREATED_BY = "createdBy",
}

export enum FILTER_ID {
  ORDER_STATE = "orderState",
  SHIPPING_METHOD = "shippingMethod",
  PAYMENT_METHOD = "paymentMethod",
  PLATFORM = "platform",
  PRODUCT_NAME = "productName",
  CUSTOMER_NAME = "customerName",
  PAYMENT_BANK_NAME = "paymentBankName",
  SHIPPING_DESCRIPTION = "shippingDescription",
  SHIPPING_ADDRESS = "shippingAddress",
  GRAND_TOTAL = "grandTotal",
  SHIPPING_DISCOUNT = "shippingDiscount",
  PRODUCT_DISCOUNT = "productDiscount",
  DATE_RANGE = "dateRange",
  PRODUCT_SKU_CODE = "productSKUCode",
  PRICE = "price",
  TOTAL = "total",
  CATEGORIES_LIST = "categoriesList",
  CATEGORIES_ID = "categoryId",
  CUSTOMER_TAG = "customerTag",
  CREATED_BY = "createdBy",
  // BROADCAST filter key
  BROADCAST_STATUS = "status",
  CARRIER_NAME = "carrierNames",
  DELIVERY_SCHEDULE_RANGE = "deliveryScheduleDateRange",
  PRINTED_PARCEL = "isPrintedParcel",
  HAS_TRACKING = "hasTrackingNumber",
}

export type FilterItemType = {
  id: FILTER_ID; // TODO: filter this field out when using in GQL query
  orderState?: string[];
  shippingMethod?: string[];
  paymentMethod?: string[];
  platform?: string[];
  productName?: string[];
  customerName?: string[];
  paymentBankName?: string[];
  shippingDescription?: string[];
  shippingAddress?: string[];
  grandTotal?: { min: number; max: number };
  shippingDiscount?: { min: number; max: number };
  productDiscount?: { min: number; max: number };
  dateRange?: { start: string; end: string };
  categoryId?: number[];
  price?: { min: number; max: number };
  total?: { min: number; max: number };
  productSKUCode?: string[];
  customerTag?: string[];
  isPrintedParcel?: boolean;
  hasTrackingNumber?: boolean;
};

export type ProductReportFilterInput = {
  categoryId: number[]; // string name of product code
  dateRange: { start: string; end: string }; // this field is default display
  productName: string[]; // string name of product name
  productSKUCode: string[]; // string name of product code
  price: { min: number; max: number };
  total: { min: number; max: number };
  orderBy: orderByType;
  sortBy: OrderSortBy;
};

export type OrderReportFilterInput = {
  customerName: string[]; // TEXT
  customerTag: string[];
  createdBy: string[];
  grandTotal: { min: number; max: number }; //
  shippingDiscount: { min: number; max: number }; //
  productDiscount: { min: number; max: number }; //
  orderState: OrderStateType[]; // this field is default display
  dateRange: { start: string; end: string }; // this field is default display
  platform: PlatformType;
  paymentMethod: PaymentMethodType;
  paymentBankName: string[];
  shippingMethod: ShippingMethodType;
  shippingDescription: string[]; // string name of shipping
  productName: string[]; // string name of product name
  shippingAddress: string[]; // string name of address
  orderBy: orderByType;
  sortBy: OrderSortBy;
  isPrintedParcel: boolean;
  hasTrackingNumber: boolean;
};

export enum FILTER_SELECT_TYPE { // using to determine which option should be single or multiple choices
  CUSTOM_OPTIONS = "CUSTOM_OPTIONS",
  CUSTOMER_TAG = "CUSTOMER_TAG",
  DATE_RANGE = "DATE_RANGE",
  EMAIL = "EMAIL",
  NUMBER_RANGE = "NUMBER_RANGE",
  STRICT_OPTIONS = "STRICT_OPTIONS",
}

export enum PrintedParcel {
  PRINTED_YES = "PRINTED_YES",
  PRINTED_NO = "PRINTED_NO",
}

export enum HasTrackingNumber {
  HAS_TRACKING_YES = "HAS_TRACKING_YES",
  HAS_TRACKING_NO = "HAS_TRACKING_NO",
}

export type LoadOptionsType = (inputValue: string, callback: (options: OptionsType<OptionType>) => void) => void;
