import { useQuery } from "@apollo/client";
import moment from "moment";

import { ORDERS } from "graphql/order/query";

type UseShipmentTransactionPropsType = {
  projectId: string;
  startDate: Date;
  endDate: Date;
  page: number;
  rowsPerPage: number;
};

const useShipmentTransaction = ({ projectId, startDate, endDate, page, rowsPerPage }: UseShipmentTransactionPropsType) => {
  const variables = {
    projectId,
    offset: page - 1, 
    limit: rowsPerPage,
  };
  
  const { loading: isLoading, data } = useQuery(ORDERS, {
    variables: {
      projectId,
      offset: variables.offset,
      limit: variables.limit,
      filter: {
        startDate: moment(startDate).utc().toISOString(),
        endDate: moment(endDate).utc().toISOString(),
      },
    },
  });

  if (data && data.orders) {
    const { results, total } = data.orders;

    const transactions = results.map((result: any) => {
      return {
        id: result.id,
        orderNumber: result.orderNumber,
        state: result.state,
        customerName: `${result.firstName} ${result.lastName || ""}`,
        shippingPrice: result.shippedDetail?.detail?.[0]?.shippingCostByActualWeight,
        createdAt: result.createdAt,
      };
    });

    return {
      results: transactions,
      isLoading,
      total,
    };
  }

  return {
    results: [],
    isLoading,
    total: 0,
  };
};

export default useShipmentTransaction;