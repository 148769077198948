import { Bank } from "./Bank";

export enum KYCStatus {
  UNVERIFIED = "UNVERIFIED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  VERIFIED = "VERIFIED",
}

export type BankInfo = {
  bank: Bank;
  bankAccountName: string;
  bankAccountNo: string;
};

export enum KYCInformationType {
  PERSONAL = "PERSONAL",
  COMPANY = "COMPANY",
}

export interface KYCVerification {
  id: string;
  projectId: string;
  status: KYCStatus;
  reason: string;
  firstPageOfBankAccountPath: string;
  bank: Bank;
  bankAccountName: string;
  bankAccountNo: string;
  verifiedBy: string;
  createdAt: Date;
  updatedAt: Date;

  type: KYCInformationType;
  name: string;
  taxId: string;
  titleName: string;
  address: string;
  subDistrict: string;
  district: string;
  province: string;
  postalCode: string;
  documentVerificationCertificatePath: string;
}

export type KYCVerificationInput = {
  bank: Bank;
  bankAccountName: string;
  bankAccountNo: string;
  firstPageOfBankAccountPath: string;
  type?: KYCInformationType;
  name: string;
  taxId: string;
  titleName?: string;
  address: string;
  subDistrict: string;
  district: string;
  province: string;
  postalCode: string;
  documentVerificationCertificatePath: string;
  selfWithIdImage?: string;
  email?: string;
  phoneNumber?: string;
};

export enum DeeplePayWalletTransactionStatus {
  GENERATE = "GENERATE",
  AUTHORIZE = "AUTHORIZE",
  SETTLE = "SETTLE",
  VOID = "VOID",
  DECLINE = "DECLINE",
}

export interface DeeplePayWallet {
  balance: number;
  updatedAt: string;
}

export interface DeeplePayWalletData {
  deeplePayWallet: DeeplePayWallet;
}

export interface DeeplePayFeeRate {
  qrCash: FeeRate;
  mobileBanking: FeeRate;
  withdrawal: FeeRate;
}

export interface FeeRate {
  fix: number;
  min: number;
  rate: number;
}
export interface DeeplePayWalletTransaction {
  amount: number;
  status: DeeplePayWalletTransactionStatus;
}

export enum DeeplePayPaymentChannel {
  QR_CASH = "QR_CASH",
  QR_CREDIT = "QR_CREDIT",
  CREDIT_CARD = "CREDIT_CARD",
  MOBILE_BANKING = "MOBILE_BANKING",
  TRUE_MONEY_WALLET = "TRUE_MONEY_WALLET",
}

export enum DeeplePayPaymentChannelName {
  QR_CASH = "QR_CASH",
  QR_CREDIT = "QR_CREDIT",
  SCB_MOBILE_BANKING = "SCB_MOBILE_BANKING",
  KBANK_MOBILE_BANKING = "KBANK_MOBILE_BANKING",
  KRUGSRI_MOBILE_BANKING = "KRUGSRI_MOBILE_BANKING",
  VISA = "VISA",
  MASTERCARD = "MASTERCARD",
  JCB = "JCB",
  TRUE_MONEY_WALLET = "TRUE_MONEY_WALLET",
}

export interface DeeplePayPaymentChannelDetail {
  name: DeeplePayPaymentChannelName;
  fee: FeeRate;
}

export interface DeeplePayPaymentChannelConfiguration {
  type: DeeplePayPaymentChannel;
  isActive: boolean;
  isSelectable: boolean; // some payment channel cannot disable
  minimum: number;
  channels: DeeplePayPaymentChannelDetail[];
}

export interface DeeplePayPaymentChannelConfigurationInput {
  type: DeeplePayPaymentChannel;
  isActive: boolean;
  minimum?: number;
}

export type KYCCorporateInfo = {
  type: KYCInformationType;
  documentVerificationCertificatePath: string;
  name: string;
  taxId: string;
  address: string;
  subDistrict: string;
  district: string;
  province: string;
  postalCode: string;
};

export type KYCPersonalInfo = {
  type: KYCInformationType;
  documentVerificationCertificatePath: string;
  titleName: string;
  name: string;
  taxId: string;
  address: string;
  subDistrict: string;
  district: string;
  province: string;
  postalCode: string;
  email?: string;
  phoneNumber?: string;
};

export type KYCInfo = KYCCorporateInfo | KYCPersonalInfo;

export enum DeeplePayTransactionType {
  INCOME = "INCOME",
  WITHDRAW = "WITHDRAW",
}

export type DeeplePayFeeInvoice = {
  id?: string;
  projectId: string;
  path?: string;
  invoiceNumber?: string;
  amount: number;
  createdAt: string;
  updatedAt: string;
};

export type DeeplePayFeeInvoicesQuery = {
  deeplePayInvoices: DeeplePayFeeInvoice[];
};

export type DeeplePayFeeInvoicesQueryVariables = {
  projectId: string;
  year: string;
};

export enum KYCValidationType {
  KYC_PHONE_NUMBER_USED_ALREADY = "KYC_PHONE_NUMBER_USED_ALREADY",
  KYC_EMAIL_USED_ALREADY = "KYC_EMAIL_USED_ALREADY",
  KYC_BANK_ACCOUNT_NO_USED_ALREADY = "KYC_BANK_ACCOUNT_NO_USED_ALREADY",
}