import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import moment from "moment";

import Typography from "components/Typography";
import COLORS from "constants/Colors";
import { convertPriceFormat } from "utils/common";
import i18n from "utils/i18n";
import { ORDER_STATUSES } from "constants/SalesReport";

import MobileCollapse from "../../components/MobileCollapse";

interface MobileShipmentTable {
  data: any[];
}

const MobileShipmentTable: FC<MobileShipmentTable> = ({ data }) => {
  return (
    <>
      {data.map((transaction) => {
        return (
          <MobileCollapse
            key={transaction.id}
            leftSideRender={
              <Box>
                <Typography variant="body3" color={COLORS.DarkGray}>
                  {transaction.orderNumber}
                </Typography>
                <Typography variant="body4" color={COLORS.DarkLight}>
                  {transaction.customerName}
                </Typography>
              </Box>
            }
            rightSideRender={
              <Box textAlign="right">
                <Typography variant="body3" color={COLORS.DarkGray}>
                  {transaction.shippingPrice ? `฿ ${convertPriceFormat(transaction.shippingPrice)}` : "-"}
                </Typography>
                <Typography variant="body4" color={COLORS.DarkGray}>
                  {i18n.t(ORDER_STATUSES[transaction.state])}
                </Typography>
              </Box>
            }
            expandRender={
              <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" className="my-2">
                  <Typography variant="title8" color={COLORS.DarkGray}>
                    {i18n.t("billing.transactionReport.shipment.orderNumber")}
                  </Typography>
                  <Typography variant="body3" color={COLORS.DarkGray}>
                    {transaction.orderNumber}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" className="my-2">
                  <Typography variant="title8" color={COLORS.DarkGray}>
                    {i18n.t("billing.transactionReport.shipment.orderStatus")}
                  </Typography>
                  <Typography variant="body3" color={COLORS.DarkGray}>
                    {i18n.t(ORDER_STATUSES[transaction.state])}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" className="my-2">
                  <Typography variant="title8" color={COLORS.DarkGray}>
                    {i18n.t("billing.transactionReport.shipment.customerName")}
                  </Typography>
                  <Typography variant="body3" color={COLORS.DarkGray}>
                    {transaction.customerName}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" className="my-2">
                  <Typography variant="title8" color={COLORS.DarkGray}>
                    {i18n.t("billing.transactionReport.shipment.shippingCost")}
                  </Typography>
                  <Typography variant="body3" color={COLORS.DarkGray}>
                    {transaction.shippingPrice ? `฿ ${convertPriceFormat(transaction.shippingPrice)}` : "-"}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" className="my-2">
                  <Typography variant="title8" color={COLORS.DarkGray}>
                    {i18n.t("billing.transactionReport.shipment.date")}
                  </Typography>
                  <Typography variant="body3" color={COLORS.DarkGray}>
                    {moment(transaction.createdAt).format("DD/MM/YYYY HH:mm")}
                  </Typography>
                </Box>
              </Box>
            }
          />
        );
      })}
    </>
  );
};

export default MobileShipmentTable; 