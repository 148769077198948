import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Typography from "components/Typography";
import { SvgIcon } from "components/Icon";
import { IcWallet } from "components/SvgIcons";
import { convertPriceFormat } from "utils/common";
import COLORS from "constants/Colors";
import { TotalBalanceWrapper } from "./styled";

interface TotalLogisticCredit {
  total?: number;
  isMobile: boolean;
}

const TotalLogisticCredit: FC<TotalLogisticCredit> = ({ total, isMobile }) => {
  const { t } = useTranslation();

  return (
    <TotalBalanceWrapper container>
      <Grid item xs={12}>
        <Typography variant="title10" color={COLORS.DarkLight}>
          <SvgIcon viewBox="0 -12 24 24" component={IcWallet} fontSize="small" className="mr-2" />
          {t("deeplePay.totalBalance.title")}
        </Typography>
      </Grid>
      <Grid item xs={12} className={isMobile ? "price" : "pt-4 price"}>
        {t("THB")} {convertPriceFormat(total || 0)}
      </Grid>
    </TotalBalanceWrapper>
  );
};

export default TotalLogisticCredit;
