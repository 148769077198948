import { InvoiceTypes } from "types/invoice";

export const CREDIT_TRANSACTION_TYPES = [InvoiceTypes.MAIN, InvoiceTypes.LOGISTIC];
export const CREDIT_TRANSACTION_TYPES_WITHOUT_LALAMOVE = [InvoiceTypes.MAIN];

export enum TRANSACTION_TYPES {
  "CREDIT_CARD" = "transactionType.creditCard",
  "FREE_CREDIT" = "transactionType.freeCredit",
  "LALAMOVE" = "transactionType.lalamove",
  "PACKAGE" = "transactionType.package",
  "PACKAGE_AND_ADDON" = "transactionType.packageAndAddon",
  "ADDON" = "transactionType.addOn",
  "PROMO_CODE" = "transactionType.promoCode",
  "TOP_UP" = "transactionType.topUp",
  "SHIPPOP" = "transactionType.shippop",
  "MY_EXPRESS" = "transactionType.myExpress",
}
