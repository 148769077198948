import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import { EXPORT_SHIPMENT_TRANSACTIONS } from "graphql/wallet/query";

import { ExportFileType } from "types/SalesReport";

import { downloadFile } from "utils/common";
import moment from "moment";

type UseExportShipmentTransactionType = {
  readonly exportShipmentTransactions: string;
};

type useExportShipmentTransactionProps = { onCompleted?: () => void; onError?: () => void };

const useExportShipmentTransactions = ({ onCompleted, onError }: useExportShipmentTransactionProps) => {
  const [exportShipmentTransactionsUrl, { data, loading }] = useLazyQuery<UseExportShipmentTransactionType>(
    EXPORT_SHIPMENT_TRANSACTIONS,
    {
      onError,
    },
  );

  useEffect(() => {
    const url: string = data?.exportShipmentTransactions || "";
    if (url) {
      const fileName = url.split("?")[0].split("/").pop();
      if (fileName) {
        downloadFile(url, fileName).then(() => {
          if (onCompleted) onCompleted();
        });
      }
    }
  }, [data, onCompleted]);

  const exportShipmentTransactions = async (
    projectId: string,
    exportFileType: ExportFileType,
    startDate: string,
    endDate: string,
  ) => {
    exportShipmentTransactionsUrl({
      variables: {
        projectId,
        filter: {
          startDate: moment(startDate).utc().toISOString(),
          endDate: moment(endDate).utc().toISOString(),
        },
        fileType: exportFileType || ExportFileType.XLSX,
      },
    });
  };

  return { exportShipmentTransactions, loading };
};

export default useExportShipmentTransactions;