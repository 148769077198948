export const DEEPLE_LOGO_IMG = "/assets/logo/leanbiz-logo-black.png";
export const DEEPLE_REGISTRATION_NOT_ACCESSIBLE_IMG = "/assets/img/deeple-registration-not-accessible.png";
export const DEFAULT_AVATAR_IMG = "/assets/img/img_avatar.png";
export const NO_IMG_AVAILABLE = "/assets/img/no-img-available.png";
export const NO_IMG_DEEPLE_LOGO = "/assets/logo/leanbiz-logo-grayscale.png";
export const NO_DEEPLE_PAY_TRANSACTIONS = "/assets/img/no-deeple-pay-transactions.svg";

export const AMERICAN_EXPRESS_LOGO = "/assets/img/amex_logo.svg";
export const JCB_LOGO = "/assets/img/jcb_logo.svg";
export const MASTERCARD_LOGO = "/assets/img/mastercard_logo.svg";
export const VISA_LOGO = "/assets/img/visa_logo.svg";
export const CREDIT_CARD_BG = "/assets/img/credit_card_background.svg";
export const CHAT_FLOW = "/assets/img/chat-flow.svg";
export const WEB_VIEW_FLOW = "/assets/img/web-view-flow.svg";
export const WAITING_IMAGE = "/assets/img/waiting.svg";
export const UPLOAD_IMAGE = "/assets/img/upload-image.svg";
export const VERIFIED_STORE = "/assets/img/verified-store.png";
export const LOCK = "/assets/img/lock.svg";

const carrierLogoLink = "https://storage.googleapis.com/leanbiz-core/static/carriers-logo";

export const CARRIER_ICONS: { [key: string]: string } = {
  DEFAULT_CARRIER: `${carrierLogoLink}/default_carrier.png`,

  "Flash express": `${carrierLogoLink}/flash_express.png`,
  "Inter express": `${carrierLogoLink}/inter_express.png`,
  "J&T": `${carrierLogoLink}/j_and_t.png`,
  "Kerry drop off": `${carrierLogoLink}/kerry.png`,
  "Kerry pickup": `${carrierLogoLink}/kerry.png`,
  "NIM express chilled": `${carrierLogoLink}/nim.png`,
  "NIM express": `${carrierLogoLink}/nim.png`,
  "Porlor express": `${carrierLogoLink}/porlor.png`,
  "SCG yamato express": `${carrierLogoLink}/scg_express.png`,
  "Thaipost-EMS": `${carrierLogoLink}/thaipost_ems.png`,
  "Thaipost-Register": `${carrierLogoLink}/thaipost.png`,
  "Thaipost-MyExpress": `${carrierLogoLink}/thaipost_ems.png`,
  DHL: `${carrierLogoLink}/dhl.png`,
  Kerry: `${carrierLogoLink}/kerry.png`,
  LALAMOVE: `${carrierLogoLink}/lalamove.png`,
  NINJAVAN: `${carrierLogoLink}/ninjavan.png`,
  Sendit: `${carrierLogoLink}/sendit.png`,
  TNT: `${carrierLogoLink}/tnt.png`,
  Xpresso: `${carrierLogoLink}/xpresso.png`,
  ZTO: `${carrierLogoLink}/zto.png`,

  // icon from shippop
  "SCG yamato express chilled": `${carrierLogoLink}/scg_express_chilled_and_frozen.png`,
  "SCG yamato express frozen": `${carrierLogoLink}/scg_express_chilled_and_frozen.png`,
  Ninjavan: `${carrierLogoLink}/ninjavan.png`,
  Popman: `${carrierLogoLink}/popman.png`,
  "Niko logistic": `${carrierLogoLink}/niko_logistics.png`,
  "CJ logistics": `${carrierLogoLink}/cj_logistics.png`,
  "J&T drop off": `${carrierLogoLink}/j_and_t.png`,
  "J&T pickup": `${carrierLogoLink}/j_and_t.png`,
  "True E logistics": `${carrierLogoLink}/true_e_logistics.png`,
  "JWD express": `${carrierLogoLink}/jwd_express.png`,
  "JWD express chilled": `${carrierLogoLink}/jwd_express.png`,
  "JWD express frozen": `${carrierLogoLink}/jwd_express.png`,
  "BEST express": `${carrierLogoLink}/best_express.png`,
  "BEE express": `${carrierLogoLink}/bee_express.png`,
  Aramex: `${carrierLogoLink}/aramex.png`,
};

export const BANK_LOGO_SQUARE: { [key: string]: string } = {
  ANZ_BANK: "/assets/logo/bank/square/anz.svg",
  BAAC: "/assets/logo/bank/square/baac.svg",
  BANGKOK_BANK: "/assets/logo/bank/square/bbl.svg",
  BANK_OF_AMERICA: "/assets/logo/bank/square/boa.svg",
  BANK_OF_CHINA: "/assets/logo/bank/square/boc.svg",
  BANK_OF_THAILAND: "/assets/logo/bank/square/bot.png",
  BAY: "/assets/logo/bank/square/bay.svg",
  BNP_PARIBAS: "/assets/logo/bank/square/bnp.svg",
  CIMB: "/assets/logo/bank/square/cimb.svg",
  CITIBANK: "/assets/logo/bank/square/citi.svg",
  DEUTSCHE_BANK: "/assets/logo/bank/square/db.svg",
  GHB: "/assets/logo/bank/square/ghb.svg",
  GSB: "/assets/logo/bank/square/gsb.svg",
  HONGKONG_AND_SHANGHAI: "/assets/logo/bank/square/hsbc.svg",
  ICBC: "/assets/logo/bank/square/icbc.svg",
  ISLAMIC_BANK: "/assets/logo/bank/square/ibank.svg",
  JP_MORGAN_CHASE: "/assets/logo/bank/square/jpm.svg",
  KBANK: "/assets/logo/bank/square/kbank.svg",
  KIATNAKIN: "/assets/logo/bank/square/kk.svg",
  KTB: "/assets/logo/bank/square/ktb.svg",
  LH_BANK: "/assets/logo/bank/square/lhb.svg",
  MEGA: "/assets/logo/bank/square/mega.svg",
  MIZUHO: "/assets/logo/bank/square/mb.svg",
  RBS: "/assets/logo/bank/square/rbs.svg",
  SCB: "/assets/logo/bank/square/scb.svg",
  STANDARD_CHARTERED: "/assets/logo/bank/square/sc.svg",
  SUMITOMO_MITSUI: "/assets/logo/bank/square/smbc.svg",
  TISCO: "/assets/logo/bank/square/tisco.svg",
  TTB: "/assets/logo/bank/square/ttb.svg",
  UOB: "/assets/logo/bank/square/uob.svg",
  THAI_CREDIT: "/assets/logo/bank/square/tbank.svg",
};

export const BANK_LOGO_CIRCLE: { [key: string]: string } = {
  ANZ_BANK: "/assets/logo/bank/circle/anz.svg",
  BAAC: "/assets/logo/bank/circle/baac.svg",
  BANGKOK_BANK: "/assets/logo/bank/circle/bbl.svg",
  BANK_OF_AMERICA: "/assets/logo/bank/circle/boa.svg",
  BANK_OF_CHINA: "/assets/logo/bank/circle/boc.svg",
  BANK_OF_THAILAND: "/assets/logo/bank/circle/bot.png",
  BAY: "/assets/logo/bank/circle/bay.svg",
  BNP_PARIBAS: "/assets/logo/bank/circle/bnp.svg",
  CIMB: "/assets/logo/bank/circle/cimb.svg",
  CITIBANK: "/assets/logo/bank/circle/citi.svg",
  DEUTSCHE_BANK: "/assets/logo/bank/circle/db.svg",
  GHB: "/assets/logo/bank/circle/ghb.svg",
  GSB: "/assets/logo/bank/circle/gsb.svg",
  HONGKONG_AND_SHANGHAI: "/assets/logo/bank/circle/hsbc.svg",
  ICBC: "/assets/logo/bank/circle/icbc.svg",
  ISLAMIC_BANK: "/assets/logo/bank/circle/ibank.svg",
  JP_MORGAN_CHASE: "/assets/logo/bank/circle/jpm.svg",
  KBANK: "/assets/logo/bank/circle/kbank.svg",
  KIATNAKIN: "/assets/logo/bank/circle/kk.svg",
  KTB: "/assets/logo/bank/circle/ktb.svg",
  LH_BANK: "/assets/logo/bank/circle/lhb.svg",
  MEGA: "/assets/logo/bank/circle/mega.svg",
  MIZUHO: "/assets/logo/bank/circle/mb.svg",
  RBS: "/assets/logo/bank/circle/rbs.svg",
  SCB: "/assets/logo/bank/circle/scb.svg",
  STANDARD_CHARTERED: "/assets/logo/bank/circle/sc.svg",
  SUMITOMO_MITSUI: "/assets/logo/bank/circle/smbc.svg",
  TISCO: "/assets/logo/bank/circle/tisco.svg",
  TTB: "/assets/logo/bank/circle/ttb.svg",
  UOB: "/assets/logo/bank/circle/uob.svg",
  THAI_CREDIT: "/assets/logo/bank/circle/tbank.svg",
};

export const QR_CASH_LOGO = "/assets/logo/qrCode/qr-cash-payment.png";
export const QR_CREDIT_LOGO = "/assets/logo/qrCode/qr-credit-payment.png";

export const MOBILE_BANKING_IMAGE: { [key: string]: string } = {
  SCB_MOBILE_BANKING: "/assets/logo/mobileBanking/scb-easy.png",
  KBANK_MOBILE_BANKING: "/assets/logo/mobileBanking/k-plus.png",
  KRUGSRI_MOBILE_BANKING: "/assets/logo/mobileBanking/kma.png",
};

export const CREDIT_CARD_IMAGE: { [key: string]: string } = {
  VISA: "/assets/logo/creditCard/visa.svg",
  MASTERCARD: "/assets/logo/creditCard/mastercard.svg",
  JCB: "/assets/logo/creditCard/jcb.svg",
};

export const E_WALLET_IMAGE: { [key: string]: string } = {
  TRUE_MONEY_WALLET: "/assets/logo/eWallet/true-money-wallet.svg",
};

export const PASSBOOK__EXAMPLE = ["/assets/img/e-passbook.svg", "/assets/img/passbook.svg"];
export const BUSINESS_ATTACHMENT__EXAMPLE = [
  "/assets/img/business-certification.png",
  "/assets/img/business-registration.png",
];
export const ID_CARD_ATTACHMENT__EXAMPLE = ["/assets/img/id-card.svg", "/assets/img/copy-id-card.svg"];
export const SELFIE_WITH_ID_EXAMPLE = ["/assets/images/selfie_with_id_example.png"];
