import React, { FC, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useGetProject from "utils/hooks/useGetProject";
import { useRenderExportedNumber, usePrintParcelInvoice, useDownloadPayslip, usePrintParcelMyExpress } from "utils/hooks/SalesReport";
import { ProjectIdType } from "types/Project";
import { ExportFileType } from "types/SalesReport";
import { MOCK_ORDER_REPORT_DATA } from "constants/Report";
import { NOTIFICATION_UPDATE } from "constants/Notification";
import ReportFilter from "domain/Report/ReportFilter";
import { notifySuccess, notifyError } from "utils/notify";
import moment from "moment";
import { OrderState, ShippingCalculationType } from "types/Order";
import { ShippingMethodType } from "domain/Report/ReportFilter/types";
import { ROWS_PER_PAGES, INITIAL_FILTER_LISTS, LIMIT_ID_SELECTED } from "./config";
import { formattedOrderReports } from "./helper";
import { OrderReportsDataType } from "./helper/formattedOrderReports";
import { Wrapper } from "./styled";
import { useQueryOrderReports, useSelectedIds, usePaginationState, useHandleEvent } from "./hooks";
import useImportTrackingNumber from "./hooks/useImportTrackingNumber";
import useExportOrders from "./hooks/useExportOrders";
import ReportTable from "./ReportTable";
import ReportPagination from "./ReportPagination";
import Placeholder from "./Placeholder";
import { getParcelMenuOptions } from "../ReportFilter/config";

export type Filter = {
  dateRange?: { start: string; end: string };
  orderState?: OrderState[];
  shippingMethod?: ShippingMethodType[];
  shippingCalculationType?: ShippingCalculationType[];
  deliveryScheduleDateRange?: { start: string; end: string };
  carrierNames?: string[];
  isPrintedParcel?: boolean;
  hasTrackingNumber?: boolean;
};

const ParcelInvoiceReportContainer: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const { selectAllRowIdsInPage, selectRowId, selectedIds } = useSelectedIds({
    warringMessage: t("parcelInvoiceReport.reachLimit", { limit: LIMIT_ID_SELECTED }),
  });

  const defaultValueFilter = {
    orderState: [OrderState.COD_PREPARE_TO_SHIP, OrderState.PREPARE_TO_SHIPPING],
    shippingCalculationType: [
      ShippingCalculationType.LALAMOVE,
      ShippingCalculationType.DISTANCE_BASE_PRICE,
      ShippingCalculationType.FIXED_PRICE,
      ShippingCalculationType.POSTAL_CODE_BASED_PRICE,
    ],
    isPrintedParcel: undefined as boolean | undefined,
    hasTrackingNumber: undefined as boolean | undefined
  };
  const [filter, setFilter] = useState<Filter>({
    ...defaultValueFilter,
    dateRange: {
      start: moment().subtract(6, "days").startOf("day").toISOString(),
      end: moment().endOf("day").toISOString(),
    },
  });

  const { page, rowsPerPage, setPage, setRowsPerPage, setSortBy, sortBy } = usePaginationState();

  const { isConsignmentReportEnabled, isAdvancedConsignmentReportEnabled, loading: projectInfoLoading } = useGetProject(
    projectId,
  );
  const isSkipQuery = !isConsignmentReportEnabled;

  const { loading: orderReportsLoading, orderReports, total, refetch } = useQueryOrderReports({
    projectId,
    isSkip: isSkipQuery,
    filter,
    sortBy,
    page,
    rowsPerPage,
  });

  const { importTrackingNumber, loading: isLoadingImportTrackingNumber } = useImportTrackingNumber(projectId, {
    onCompleted: () => {
      refetch();
      notifySuccess(t(NOTIFICATION_UPDATE.SUCCESS));
    },
  });

  const isReportDisabled = !orderReportsLoading && !projectInfoLoading && !isConsignmentReportEnabled;
  const orderReportsData = isReportDisabled ? MOCK_ORDER_REPORT_DATA : formattedOrderReports(projectId, orderReports);

  const exportedNumber = useRenderExportedNumber({ selectedIds, total });
  const { printParcelInvoice, loading: printParcelInvoiceLoading } = usePrintParcelInvoice();
  const { downloadPayslip, isLoading: downloadPayslipLoading, downloadOrderId } = useDownloadPayslip();
  const { printParcelMyExpress, loading: printParcelMyExpressLoading } = usePrintParcelMyExpress();

  const {
    handleChangeRowsPerPage,
    handleChangeSortBy,
    handleClickPageNo,
    handleClickPrintShipmentDetail,
    handleClickPrintParcelMyExpress,
  } = useHandleEvent({
    isSalesReportEnabled: isConsignmentReportEnabled,
    printParcelInvoice,
    printParcelMyExpress, 
    projectId,
    selectedIds,
    setPage,
    setRowsPerPage,
    setSortBy,
  });

  const exportOrdersOption = useMemo(
    () => ({
      onCompleted: () => {
        notifySuccess(t("shippopReport.export.success"));
      },
      onError: () => {
        notifyError(t("Please try again later"));
      },
    }),
    [t],
  );

  const { exportOrders } = useExportOrders(projectId, filter, exportOrdersOption);

  const handleExportReports = (exportFileType: ExportFileType) => {
    if (!projectId) {
      return;
    }

    exportOrders(exportFileType);
  };
  
  const handleFilterChange = (newFilter: Record<string, any>) => {
    const transformedFilter = { ...defaultValueFilter, ...newFilter };

    if (Array.isArray(transformedFilter.isPrintedParcel)) {
      transformedFilter.isPrintedParcel = transformedFilter.isPrintedParcel.includes(t("PRINTED_YES")); 
    }

    if (Array.isArray(transformedFilter.hasTrackingNumber)) {
      transformedFilter.hasTrackingNumber = transformedFilter.hasTrackingNumber.includes(t("HAS_TRACKING_YES"));
    }

    setFilter(transformedFilter);
  };

  return (
    <Wrapper>
      <ReportFilter
        setFilterValue={handleFilterChange}
        exportLabel={t("shippopReport.export.label")}
        exportedNumber={t("salesReport.exportedNumber.all")}
        printNumber={exportedNumber}
        handleImportReports={importTrackingNumber}
        projectId={projectId}
        onClickPrintParcelInvoice={handleClickPrintShipmentDetail}
        onClickPrintParcelMyExpress={handleClickPrintParcelMyExpress}
        printParcelInvoiceLoading={printParcelInvoiceLoading}
        printParcelMyExpressLoading={printParcelMyExpressLoading}
        defaultFilterList={INITIAL_FILTER_LISTS}
        isLoadingImport={isLoadingImportTrackingNumber}
        isDisabledExportButton={!orderReports.length}
        isDisabledAdvanceButtonAndShowChangePackage={!isAdvancedConsignmentReportEnabled}
        isEnablePrintParcelInvoice={isConsignmentReportEnabled}
        isEnablePrintParcelMyExpress={isConsignmentReportEnabled}
        handleExportReports={handleExportReports}
        menuOptions={getParcelMenuOptions(t)}
      />

      {
        // render when no orderReports
        !orderReportsLoading && !projectInfoLoading && orderReports.length === 0 && isConsignmentReportEnabled && (
          <Placeholder message={t("salesReport.orderReports.placeholder")} />
        )
      }

      <ReportTable
        downloadOrderId={downloadOrderId}
        downloadPayslipLoading={downloadPayslipLoading}
        isBlurContent={isReportDisabled}
        onChangeSortBy={handleChangeSortBy}
        onClickDownloadPayslip={downloadPayslip}
        onSelectAllRowInPage={selectAllRowIdsInPage}
        onSelectRow={selectRowId}
        orderReports={orderReportsData as OrderReportsDataType[]}
        orderReportsLoading={orderReportsLoading}
        projectId={projectId}
        projectInfoLoading={projectInfoLoading}
        selectedIds={selectedIds}
      />

      {!isReportDisabled && (
        <ReportPagination
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onClickPageNo={handleClickPageNo}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGES}
          total={total}
        />
      )}
    </Wrapper>
  );
};

export default ParcelInvoiceReportContainer;
