import React, { FC, useState, useMemo } from "react";
import { Grid } from "@material-ui/core";
import { IcClose } from "components/SvgIcons";
import Typography from "components/Typography";
import {
  BUSINESS_ATTACHMENT__EXAMPLE,
  ID_CARD_ATTACHMENT__EXAMPLE,
  PASSBOOK__EXAMPLE,
  SELFIE_WITH_ID_EXAMPLE,
} from "constants/Image";
import COLORS from "constants/Colors";
import { useTranslation } from "react-i18next";
import {
  CloseIcon,
  ExampleDescription,
  ExampleImageBox,
  ExampleImageWrapper,
  ExampleSideBar,
  MobileImageCountBox,
} from "./styled";

export interface AttachmentExamplePropsType {
  type: "bankAccount" | "personal" | "business" | "selfWithId";
  setIsOpenAttachmentExample: (value: boolean) => void;
  isMobile: boolean;
}

const ATTACHMENT_ITEMS = {
  bankAccount: {
    title: "deeplePay.verifyBilling.bankingInfo.example.title",
    description: "deeplePay.verifyBilling.example.description",
    images: PASSBOOK__EXAMPLE,
  },
  personal: {
    title: "deeplePay.verifyBilling.personalInfo.individual.example.title",
    description: "deeplePay.verifyBilling.example.description",
    images: ID_CARD_ATTACHMENT__EXAMPLE,
  },
  business: {
    title: "deeplePay.verifyBilling.personalInfo.corporate.example.title",
    description: "deeplePay.verifyBilling.example.description",
    images: BUSINESS_ATTACHMENT__EXAMPLE,
  },
  selfWithId: {
    title: "deeplePay.verifyBilling.selfWithId.example.title",
    description: "deeplePay.verifyBilling.example.description",
    images: SELFIE_WITH_ID_EXAMPLE,
  },
};

const AttachmentExample: FC<AttachmentExamplePropsType> = ({ setIsOpenAttachmentExample, isMobile, type }) => {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(0);

  const exampleTitle = useMemo(() => ATTACHMENT_ITEMS[type].title, [type]);

  return isMobile ? (
    <Grid container justify="flex-start" className={isMobile ? "w-100 px-6" : "h-100 w-100"}>
      <ExampleDescription className={isMobile ? "w-100" : ""}>
        <CloseIcon onClick={() => setIsOpenAttachmentExample(false)} component={IcClose} fontSize="large" />
        <Grid className="w-100" justify="space-between">
          <Typography variant="title7">{t(exampleTitle)}</Typography>
        </Grid>
        <Typography className="pt-2" variant="body2">
          {t(ATTACHMENT_ITEMS[type].description)}
        </Typography>
        <ExampleImageWrapper>
          <img alt={ATTACHMENT_ITEMS[type].images[selectedImage]} src={ATTACHMENT_ITEMS[type].images[selectedImage]} />
        </ExampleImageWrapper>
      </ExampleDescription>
      <MobileImageCountBox>
        {ATTACHMENT_ITEMS[type].images.map((item, index) => (
          <div
            key={`mobile-dot-${item}`}
            onClick={() => setSelectedImage(index)}
            style={{ background: selectedImage === index ? COLORS.Primary : COLORS.LightBlue }}
          />
        ))}
      </MobileImageCountBox>
    </Grid>
  ) : (
    <Grid container className={isMobile ? "w-100 px-6" : "h-100 w-100"}>
      <ExampleSideBar>
        <Grid className="w-100" justify="center">
          <Typography variant="body3">{t("deeplePay.verifyBilling.example.sample")}</Typography>
        </Grid>
        <Grid className="w-100 pt-4" justify="space-between">
          {ATTACHMENT_ITEMS[type].images.map((item, index) => (
            <ExampleImageBox
              key={`desktop-thumb-${item}`}
              className={selectedImage === index ? "selected" : ""}
              onClick={() => setSelectedImage(index)}
            >
              <img alt="img" src={item} />
            </ExampleImageBox>
          ))}
        </Grid>
      </ExampleSideBar>
      <ExampleDescription>
        <CloseIcon onClick={() => setIsOpenAttachmentExample(false)} component={IcClose} fontSize="large" />
        <Grid className="w-100" justify="space-between">
          <Typography variant="title7">{t(exampleTitle)}</Typography>
        </Grid>
        <Typography className="pt-2" variant="body2">
          {t(ATTACHMENT_ITEMS[type].description)}
        </Typography>
        <ExampleImageWrapper>
          <img alt={ATTACHMENT_ITEMS[type].images[selectedImage]} src={ATTACHMENT_ITEMS[type].images[selectedImage]} />
        </ExampleImageWrapper>
      </ExampleDescription>
    </Grid>
  );
};

export default AttachmentExample;
