import React from "react";
import { useParams, Switch, Route, Redirect } from "react-router-dom";
import { DEEPLE_PAY_FEE_INVOICE, PAYMENT_CHANNEL, PROJECT, TRANSACTION, COD, PAYMENTS, AUTO_VERIFY_SLIP } from "constants/Router";
import useTabWithLink from "utils/hooks/useTabWithLink";
import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";

import PrivateRoute from "routers/PrivateRoute";
import { NotFound } from "pages/NotFound";

import { TAB_CONFIGS } from "pages/Payments/tabsConfig";
import { ProjectIdType } from "types/Project";
import Transaction from "domain/DeeplePay/Transaction";
import { NewCashOnDelivery } from "domain/DeeplePay/CashOnDelivery";
import { NewPaymentChannel } from "domain/DeeplePay/PaymentChannel";
import DeeplePayFeeInvoiceHistory from "domain/DeeplePay/DeeplePayFeeInvoice";
import AutoVerificationSlip from "domain/Payment/autoVerificationSlip";

export default function PaymentsSubRoute(): JSX.Element {
  const { projectId = "" } = useParams<ProjectIdType>();
  const { loading, isAdmin, isOwner } = useGetUserWithPermission(projectId);
  const pathIndex = 2;
  const { rootPath, defaultTabPath } = useTabWithLink(TAB_CONFIGS, PAYMENTS, projectId, pathIndex);

  return (
    <Switch>
      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${TRANSACTION}`}
        isAllowed={isAdmin}
        redirectTo="/"
        component={Transaction}
      />
      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${PAYMENT_CHANNEL}`}
        isAllowed={isAdmin}
        redirectTo="/"
        component={NewPaymentChannel}
      />
      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${COD}`}
        isAllowed={isAdmin}
        redirectTo="/"
        component={NewCashOnDelivery}
      />
      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${DEEPLE_PAY_FEE_INVOICE}`}
        isAllowed={isAdmin}
        redirectTo="/"
        component={DeeplePayFeeInvoiceHistory}
      />
      <PrivateRoute
        exact
        loading={loading}
        path={`${rootPath}/${AUTO_VERIFY_SLIP}`}
        isAllowed={isOwner}
        redirectTo="/"
        component={AutoVerificationSlip}
      />
      <PrivateRoute exact loading={loading} path={`${rootPath}`} isAllowed={isAdmin} redirectTo="/">
        <Redirect to={defaultTabPath ? `/${PROJECT}/${projectId}/${PAYMENTS}/${defaultTabPath}` : `/${PROJECT}`} />
      </PrivateRoute>
      <Route path={`${rootPath}/*`}>
        <NotFound />
      </Route>
    </Switch>
  );
}
