import { InvoiceTypes } from "types/invoice";

export enum TransactionType {
  CREDIT_CARD = "CREDIT_CARD",
  FREE_CREDIT = "FREE_CREDIT",
  LALAMOVE = "LALAMOVE",
  PACKAGE = "PACKAGE",
  PROMO_CODE = "PROMO_CODE",
  TOP_UP = "TOP_UP",
  SHIPPOP = "SHIPPOP",
  MY_EXPRESS = "MY_EXPRESS",
}

export type CreditType = {
  type: InvoiceTypes;
  credit: number;
};

export type CreditQueryType = {
  mainCredit: number;
  logisticCredit: number;
};

export type CreditTransaction = {
  id: string;
  transactionType: TransactionType;
  transactionId: string;
  type: TransactionCreditType;
  value: number;
  refId: string;
  createdAt: string;
};

export type TransactionConnectionType = {
  results: CreditTransaction[];
  total: number;
};

export type TransactionVariablesType = {
  projectId: string;
  offset: number;
  limit: number;
  start?: string;
  end?: string;
};

export type TransactionQueryType = {
  transactions: TransactionConnectionType;
};

export type FirstTransactionQueryType = {
  firstCreditTransaction: CreditTransaction;
};

export enum TransactionCreditType {
  MAIN = "MAIN",
  LOGISTIC = "LOGISTIC",
}