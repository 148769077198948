import { IcBilling, IcPlanUsage, IcPlanUpgrade, IcTransaction } from "components/SvgIcons";
import { BILLING, PLAN_ADDON, PLAN_USAGE, PLAN_UPGRADE, TRANSACTION } from "constants/Router";

export const TAB_CONFIGS_DEFAULT = [
  {
    id: PLAN_USAGE,
    title: "planUsage.header",
    svgIcon: IcPlanUsage,
  },
  {
    id: TRANSACTION,
    title: "deeplePay.header.shipmentTransactionReport",
    svgIcon: IcTransaction,
  },
  {
    id: BILLING,
    title: "planBilling.header",
    svgIcon: IcBilling,
  },
  {
    id: PLAN_UPGRADE,
    title: "planUpgrade.header",
    svgIcon: IcPlanUpgrade,
  },
  {
    id: PLAN_ADDON,
    title: "planAddon.header",
    svgIcon: IcPlanUpgrade,
  },
];
