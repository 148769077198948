import { OrderReportResult } from "types/Report";
import { convertPriceFormat } from "utils/common";
import { getCustomerAddressText } from "utils/address";
import { getCourierNameByShippingMethod } from "utils/shipping";
import { formattedSubTable } from "./formattedSubTable";
import { DEFAULT_VALUE } from "../config";

export const formattedOrderReports = (
  orderReportsInput: OrderReportResult[],
  projectId: string | number | undefined,
) => {
  const newFormattedOrderReports = orderReportsInput.map((order) => {
    const streetAddress = order?.streetAddress || "";
    const streetAddress2 = order?.streetAddress2 || "";
    const subDistrict = order?.subDistrict || "";
    const district = order?.district || "";
    const province = order?.province || "";
    const postalCode = order?.postalCode || "";
    const discountProductPrice = order?.discountTotalPrice || 0;
    const productPrice = order?.totalPrice || 0;
    const shippingCostSettled = order?.shippingCostSettled === 0 ? DEFAULT_VALUE : order?.shippingCostSettled;

    return {
      id: order?.id,
      orderNumber: order?.orderNumber || DEFAULT_VALUE,
      customerName: order?.customer.displayName || order?.customer.name || DEFAULT_VALUE,
      state: order?.state || DEFAULT_VALUE,
      shippingMethod: order?.shippingMethod || DEFAULT_VALUE,
      note: order?.note || DEFAULT_VALUE,
      deliverySchedule: order?.deliverySchedule || DEFAULT_VALUE,
      shippingPrice: convertPriceFormat(order?.shippingPrice || 0),
      shippingCostSettled,
      streetAddress:
        getCustomerAddressText(
          {
            streetAddress,
            streetAddress2,
            district,
            subDistrict,
            province,
            postalCode,
          },
          [],
        ) || DEFAULT_VALUE,
      phoneNumber: order?.phoneNumber || DEFAULT_VALUE,
      email: order?.email || DEFAULT_VALUE,
      createdBy: order?.createdBy || DEFAULT_VALUE,
      trackingNumber: order?.trackingNumber || DEFAULT_VALUE,
      vatOptions: order?.vatOptions || DEFAULT_VALUE,
      productPrice: convertPriceFormat(productPrice + discountProductPrice),
      discountTotalPrice: convertPriceFormat(discountProductPrice),
      discountShippingPrice: convertPriceFormat(order?.discountShippingPrice || 0),
      grandTotal: convertPriceFormat(order?.grandTotal || 0),
      paymentAt: order?.paymentAt || DEFAULT_VALUE,
      referenceCode: order?.referenceCode || DEFAULT_VALUE,
      subTable: formattedSubTable(order?.orderProducts || []),
      billNumber: {
        projectId,
        orderId: order?.id,
        billNumber: order?.bill?.billNumber,
      },
      additionalDiscount: convertPriceFormat(order?.additionalDiscount || 0),
      adminsNote: order?.adminsNote || "",
      largestDimension: order?.largestDimension || [],
      totalWeight: order?.totalWeight || 0,
      shippingDescription: getCourierNameByShippingMethod(order?.shippingDescription || DEFAULT_VALUE, order.shipping),
    };
  });

  return newFormattedOrderReports;
};
