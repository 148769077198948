import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import styled from "styled-components";
import COLORS from "constants/Colors";
import Button from "components/Button";
import Grid from "components/Grid";
import DeepleTypography from "components/Typography";
import { VARIANTS } from "constants/Typography";

import { Theme } from "types/Theme";

type TabMenuPropsType = {
  theme: Theme;
  isActive: boolean;
};

type TabHeaderPropsType = {
  theme: Theme;
};

type CollapseContainerPropsType = {
  isExpand: boolean;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TabMenu = styled(({ isActive, ...rest }) => <Tab {...rest} />).attrs((props: TabMenuPropsType) => {
  const { theme, isActive } = props;
  return { theme, isActive };
})`
  margin-right: 5px;
  font-weight: bold;
  min-width: fit-content;
  background-color: ${({ theme, isActive }) => (isActive ? theme.COLORS.White : "none")};
  border: ${({ theme, isActive }) => (isActive ? `solid 1px ${theme.COLORS.LightBlue}` : "none")};
  height: 48px;
  border-radius: 8px;
  span {
    color: ${({ theme, isActive }) => (isActive ? theme.COLORS.DarkGray : theme.COLORS.DarkLight)};
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TabHeader = styled(({ ...rest }) => <Tabs {...rest} />).attrs((props: TabHeaderPropsType) => {
  const { theme } = props;
  return { theme };
})`
  background-color: none;
  border: none;
`;

export const ExportButton = styled(Button)`
  border-radius: 8px;
  padding-right: 16px;
  padding-left: 16px;
`;

export const GridShowingResult = styled(Grid)`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    order: 2;
    padding-bottom: 8px;
  }
`;

export const GridPagination = styled(Grid)`
  justify-content: flex-end;
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex: 1;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    order: 1;
    justify-content: center;
  }
`;

export const GridRowsPerPage = styled(Grid)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex: 0;
    flex-basis: 180px;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    order: 3;
    padding-bottom: 8px;
  }
`;

export const GridLoading = styled.div`
  position: absolute;
  z-index: 10;
  top: calc(50% - 45px);
  left: calc(50% - 45px);
`;

export const Wrapper = styled.div`
  margin-top: 16px;
  background-color: ${({ theme }) => theme.COLORS.White};
  border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
  border-radius: 8px;
  position: relative;
  font-size: ${VARIANTS.body4.size};
  flex-direction: column;
  padding: 0 0 16px 0;
  width: 100%;

  .tabs span {
    font-weight: 500;
  }
`;

export const Typography = styled(({ fontWeight: _fontWeight, ...rest }) => <DeepleTypography {...rest} />).attrs(
  ({ fontWeight, variant }) => {
    return { fontWeight, variant };
  },
)`
  ${({ variant }) => (variant ? "" : "font-size: inherit;")};
  font-weight: ${({ fontWeight }) => fontWeight || "inherit"};
`;

export const Hidden = styled(({ isHidden: _isHidden, ...rest }) => <div {...rest} />).attrs(({ isHidden }) => {
  return { isHidden };
})`
  display: ${({ isHidden }) => (isHidden ? "none" : "block")};
`;

export const DisabledReportWrapper = styled.div`
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const DisabledReportBox = styled.div`
  background-color: rgb(255 255 255 / 0.75);
  padding: 24px;
  margin-top: 64px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  text-align: center;
`;

export const PaginationWrapper = styled.div`
  font-size: ${VARIANTS.body4.size};
  color: ${({ theme }) => theme.COLORS.DarkGray};

  .page-no {
    list-style-type: none;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.COLORS.LightBlue};
    background-color: white;
    margin: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      cursor: pointer;
    }
    a {
      padding: 5px 10px;
      :focus {
        outline: none;
      }
    }
  }

  .active {
    color: white !important;
    background-color: ${({ theme }) => theme.COLORS.Primary};
  }

  .break {
    list-style-type: none;
    padding: 5px 10px;
  }

  .previous,
  .next {
    border: 1px solid #e6e6e6;
  }
`;

export const CollapseWrapper = styled.div<CollapseContainerPropsType>`
  background-color: ${(props) => (props.isExpand ? COLORS.PrimaryNude : "none")};
  width: 100%;
  box-sizing: border-box;
`;
